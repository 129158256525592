<template>
  <div class="hows-mom-bar">
    <img src="@/ui/assets/demo-logo.png" alt="" class="hows-mom logo" />
  </div>
  <router-view />
</template>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.hows-mom-bar {
  margin: 0 auto;
  padding: 12px 24px;
  width: 80%;
  display: flex;
}

.hows-mom-bar > .hows-mom.logo {
  height: 88px;
  padding-top: 24px;
}

</style>
