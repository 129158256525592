import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";

export default class FirebaseService {
  private static _instance: FirebaseService;
  private _app!: FirebaseApp;

  private readonly _firebaseConfig!: FirebaseOptions;

  private constructor() {
    this._firebaseConfig = {
      apiKey: "AIzaSyBsa25kpyGzqIoe_k6o-acObE2YXNAWTqI",
      authDomain: "howsmom-prototype.firebaseapp.com",
      projectId: "howsmom-prototype",
      storageBucket: "howsmom-prototype.appspot.com",
      messagingSenderId: "313097490241",
      appId: "1:313097490241:web:e0aeb406f22dc66c31dbc0",
    };
  }

  static get instance(): FirebaseService {
    if (!this._instance) {
      this._instance = new FirebaseService();
    }
    return this._instance;
  }

  get app(): FirebaseApp {
    return this._app;
  }

  initialize(): void {
    if (!this._app) {
      this._app = initializeApp(this._firebaseConfig);
    }
  }
}
