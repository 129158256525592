import RemoteConfigRepo from "@/data/repositories/remote_config";
import Prototype from "../interfaces/prototype";

export default abstract class PrototypesManager {
  private static _repository = RemoteConfigRepo.instance;

  static async fetchPrototypes(): Promise<Array<Prototype>> {
    await this._repository.fetchAndActivate();
    const data = this._repository.get("prototype_v2");
    const parsedData = JSON.parse(data.asString()) as Record<string, Array<Prototype>>;
    return (parsedData['data']);
  }
}
