import Prototype from "../interfaces/prototype";
import PrototypesManager from "../use_cases/prototypes";

export default class PrototypesController {
  private _prototypes: Array<Prototype> = [];

  get prototypes(): Array<Prototype> {
    return this._prototypes;
  }

  async fetchPrototypes(): Promise<void> {
    this._prototypes = await PrototypesManager.fetchPrototypes();
  }
}
