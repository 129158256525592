import FirebaseService from "../services/firebase";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  RemoteConfig,
  Value,
} from "firebase/remote-config";

export default class RemoteConfigRepo {
  private static _instance: RemoteConfigRepo;
  private _remoteConfig!: RemoteConfig;

  private constructor() {
    FirebaseService.instance.initialize();
    this._remoteConfig = getRemoteConfig(FirebaseService.instance.app);
    this._remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  }

  static get instance(): RemoteConfigRepo {
    if (!this._instance) {
      this._instance = new RemoteConfigRepo();
    }
    return this._instance;
  }

  async fetchAndActivate(): Promise<void> {
    await fetchAndActivate(this._remoteConfig);
  }

  get(key: string): Value {
    return getValue(this._remoteConfig, key);
  }
}
